<template>
  <base-template :class="{'hide-head-back':isHideHeadBack+'' === '1'}">
  <!-- 这个先不改也先不用了  单独拎出了一个组件  别的页面也会用到  -->
    <div class="content" v-if="false">
      <div class="log-img" />
      <div class="head-text">Air Fish Lending Corporation</div>
      <div class="head-text">Company Registration No.: CS201915454</div>
      <div class="head-text p40">Certificate of Authority No.: 3082</div>

      <div class="main-title">
        <h6 class="title-item">Air Fish Lending Corporation - Loan Terms and Conditions (“Agreement”)</h6>
      </div>
      <p class="p40">In the event Air Fish Lending Corporation (the “Company”) agrees to grant a loan in the amount and subject to the terms provided in the Disclosure Statement (the “Loan”) to the undersigned applicant (the “Borrower”), the Loan shall be subject to the following terms and conditions:</p>
      <p class="p40">1.Borrower shall repay the Loan along with interest and charges approved by the Company and provided in Disclosure Statement (the “Payables”) in accordance with repayment schedule provided therein (“Repayment Schedule”).</p>
      <p class="p40">2.Company shall disburse the entire/part of the Loan, as applicable, from its designated bank account to the Borrower’s designated bank account or electronic wallet provided in the loan application form submitted through the Platform.</p>
      <p>3.Company is committed to fair and transparent practices and accordingly:</p>
      <div class="tab-cont">
        <p class="p10">a.abides by all applicable laws and regulations (the “Law”);</p>
        <p class="p10">b.ensures preventing inappropriate staff behavior;</p>
        <p class="p10">c.strives to educate Borrower on all aspects of Loan and the Agreement;</p>
        <p class="p10">d.allows Borrower to pre-pay the Loan, by paying interest and processing fees, as applicable, for the complete duration of the Loan;</p>
        <p class="p10">e.does not resort to undue harassment in the Loan recovery;</p>
        <p class="p10">f.set-up and operates an adequate grievance redressal system for addressing Borrower’s queries and/or Concerns;</p>
        <p class="p10">g.assures that the privacy of the Borrower shall not be compromised;</p>
        <p class="p10">h.does not charge penalty on delayed payment; however, in the event of any delay on re-payment of Loan along with Interest, an over-due charge will be levied on the principal amount payable for the delay period as mentioned in the Disclosure Statement. </p>
        <p class="p10">i.applies changes in interest rates and charges only prospectively. </p>
        <p class="p40">j.And / or does not collect security deposit / margin.</p>
      </div>
      <p>4.Borrower represents, warrants and undertakes that it/he/she:</p>
      <div class="tab-cont">
        <p class="p10">a.completely understands the Agreement and is ﬁnancially and legally capable to perform it/his/her obligations;</p>
        <p class="p10">b.information, documents and representations provided on the Platform, including the Loan Application Form (“LAF”), are true and it/he/she will inform the company within three days in case of any change in information;</p>
        <p class="p10">c.shall utilize the Loan only for purpose mentioned in the LAF and make repayment only through Company’s approved methods; and</p>
        <p class="p40">d.shall fully cooperate and abide by the Terms & Conditions (“T&C”) laid out by the Company.</p>
      </div>
      <p>5.Subject to Law, Company may:</p>
      <div class="tab-cont">
        <p class="p10">a.Withhold and/or cancel the disbursement(s) of Loan/its instalment upon breach of the Agreement and/or law by Borrower; and/or</p>
        <p class="p40">b.Sole right and discretion to approve or disapprove the loan application, disbursement of loans and collection of loans without specifying any reason whatsoever to the borrower.</p>
      </div>
      <p>6.Agreement’s term shall be from Effective Date to the time entire Payable(s) is/are repaid to Company, unless terminated earlier upon occurrence of any Event of Default (“EOD”). EOD shall be deemed to have occurred upon the Borrower’s breach of any of the terms and conditions of this Agreement, including but not limited to:</p>
      <div class="tab-cont">
        <p class="p10">a.Borrower commits a breach or fails or neglects to perform, keep or observe any of the provisions of Agreement including failure to repay for more than 45 calendar days;</p>
        <p class="p10">b.Any covenant, representation, warranty of Borrower under Agreement and/or LAF are found/proved to be incomplete, false or incorrect;</p>
        <p class="p10">c.Borrower has an insolvency notice served on it/him/her; (ii) is declared insane; (iii) convicted of any offence; and/or</p>
        <p class="p10">d.an event, which in Company’s opinion, impairs Borrower’s ability to repay Payables, occurs.</p>
        <p class="p10">e.Submission of any forged document and/or the conduct of any fraudulent act by the Borrower.</p>
        <p class="p40">f.Any other event which in the sole opinion of the Company would endanger the repayment of the Loan Amount.</p>
      </div>

      <p>7.Upon occurrence of any EOD, Company may: </p>
      <div class="tab-cont">
        <p class="p10">a.terminate the Agreement; and/or </p>
        <p class="p40">b.declare all Payables (whether due or not), immediately due, demandable and repayable.</p>
      </div>

      <p>8.If Borrower fails to make payments (as provided above) within seven days thereof, Company may at its sole discretion:</p>
      <div class="tab-cont">
        <p class="p10">a.require Borrower to pay liquidated damages equal to all unpaid payables; and/or </p>
        <p class="p40">b.exercise any other right or remedy available under Law.</p>
      </div>

      <p>9.If the Borrower requires additional time for repayment, the Borrower can apply an for extension of loan tenure for repayment provided the Borrower has already cleared the overdue charges if any before applying for extension on the application before the due date. On approval of the request: </p>
      <div class="tab-cont">
        <p class="p10">a.the Borrower agrees to pay the applicable processing fee for the extension of the loan tenure; and</p>
        <p class="p40">b.a confirmation mail/sms will be sent to Borrower stating the new due date.</p>
      </div>
      <p class="p40">10.Borrower shall indemnify, defend and hold Company harmless from and against any loss arising out of or in relation to breach of terms of Agreement by the Borrower.</p>
      <p class="p40">11.Agreement shall be subject to the exclusive jurisdiction of the courts of Bengaluru. Complaints of Borrowers in relation to Agreement would be addressed in accordance with the Complain Redressal Mechanism.</p>
      <p class="p40">12.Borrower’s personal information shall be collected/used only in accordance with terms of Agreement, the Privacy Policy on Platform (for use of information), and Law.</p>
      <p>13.Borrower understands and agrees that:</p>
      <div class="tab-cont">
        <p class="p10">a.Company may share Borrower’s, credit history and defaults to inter-alia its afﬁliates and such organizations as Company may deem ﬁt including for the purpose of fraud checks, submission of performance data to bureaus and self-regulatory organizations;</p>
        <p class="p10">b.Company may call for appropriate credit report, loan history and other information with respect to Borrower from credit bureaus, statutory bodies, tax authorizes, Credit Information Corporation, research merchants and such other organizations as Company may deem ﬁt;</p>
        <p class="p10">c.Borrower shall not hold Company liable for use of this information and/or for undertaking any such background checks and veriﬁcation; and</p>
        <p class="p40">d.Borrower has provided any false information in the loan application and/or misrepresent any facts under this transaction, then the Company has every right to pursue criminal action under the applicable laws, against Borrower.</p>
      </div>
      <p class="p40">14.Subject to Law, Company may assign / delegate any or all of its rights, powers and functions under this Agreement to third party/ies. Borrower hereby provides his unqualiﬁed consent for the same.</p>
      <p class="p40">15.Company may modify any terms of Agreement provided it is communicated to Borrower.</p>

      <p>16.Any communication in relation to Agreement shall be at:</p>
      <div class="tab-cont">
        <p class="p10">a.Borrower: address provided in LAF and/or on Platform; </p>
        <p class="p40">b.Company: Registered Ofﬁce.</p>
      </div>

      <p class="p40">17.Agreement, LAF and terms of Platform (including Borrower’s consent for use of information) shall constitute the entire agreement between parties. If any provision of this Agreement is held invalid, other provisions shall remain unaffected.</p>
      <p class="p60">18.This Agreement supersedes any previous agreement and/or understanding made either verbally or in writing by the Parties concerning the subject matter of this Agreement</p>

      <p>Name of the Lender: Air Fish Lending Corporation</p>
      <p>Date: {{ info.loan_time }}</p>
      <!-- <p class="p60">Authorized Signatory： <img class="sig-img" src="~@/assets/images/public/sig.png" alt=""></p> -->
      <p>Name of the Borrower:  {{ info.name }}</p>
      <p class="p60">Date: {{ info.loan_time }}</p>
      <!-- <p class="p60">Signature: ____________</p> -->

      <div class="main-title">
        <h6 class="title-item">Loan Agreement</h6>
      </div>
      <p class="p40">I hereby agree and provide consent to abide by the provisions of Loan Agreement (as provided herein). I understand, agree and acknowledge that the validity and enforceability of this Loan Agreement is contingent upon approval of my Loan Application submitted through this Platform.</p>
      <p class="p40">I further agree and understand that in the event Loan Application is approved by Company, my consent (provided herein) along with submission of my Loan Application Details (on this Platform) should constitute a legally enforceable agreement between Company and I. No further action for executing and/or concluding Loan Agreement would be required by either Company or I.</p>
      <p class="p40">I understand that the Lender has adopted risk-based pricing, which is arrived by taking into account, broad parameters like the customers financial and credit profile. Further, I acknowledge and confirm that the Lender shall have the discretion to change prospectively the rate of interest and other charges applicable to the Loan.</p>
      <p class="p40">hereby as the borrower I authorize Air Fish Lending Corporation as the lender to retrive my credit data from credit beuru such as experian/CIBIL/equifax/CRIF etc and report my data to the relevent authorities.</p>
      <p class="p40">Disclosure Statement</p>
      <p class="p10">1.	Term					: {{ isExamine?'130days': info.highest_term }} </p>
      <p class="p10">2.	Loan purpose 				: {{ info.useof_funds }}</p>
      <p class="p10">3.	Principal Amount			: {{ isExamine?'PHP 30,000' : info.loan_cash  }}</p>
      <p class="p10">4.	Loan Start Date				: {{ info.loan_time }} </p>
      <p class="p10">5.	Rate of Interest				: {{ isExamine?'0.1%': info.interest }}</p>
      <p class="p10">6. Processing Fees (incl GST) 		: {{ isExamine?'PHP 300': info.server_cash  }}</p>
      <!-- <p class="p10">7. Over-Due charges			: </p> -->
      <p class="p10">7.	Delayed Interest			: {{ isExamine?'0.1%': info.overdue_penalty }} </p>
      <p class="p40">8. Late Penalty				: {{ isExamine?'0.2%': info.overdue_damages}} </p>
      <p class="p40 bold">Loan Application Form</p>
      <!-- <p class="p10">1.Name				:</p>
      <img class="sig-img" src="~@/assets/images/public/sig.png" alt=""> -->
      <!-- <p class="p60">2.Proof of Identity		: {{ info.idcard }}</p> -->
      <!-- <p class="p10">3.TIN				: </p>
      <p class="p10">4.Proof of Address		:  地址</p>
      <p class="p10">5.Phone Number			: 手机号</p>
      <p class="p10">6.Bank Account Number		: 卡号 card_account</p>
      <p class="p60">7.Email ID			:</p> -->
      <p>Name of the Borrower: {{ info.name }}</p>
      <p class="p60">Proof of Identity:{{ info.idcard }}</p>
      <!-- <p class="p60">Date: {{ info.loan_time }}</p> -->
      <!-- <p class="p60">Signature: ___________</p> -->

      <div class="main-title">
        <h6 class="title-item">APPLICANT’S UNDERTAKING</h6>
      </div>
      <p class="p40">Applicant hereby agrees, represents and conﬁrms that:</p>
      <div class="tab-cont">
        <p class="p40">1.It/He/she understands that submission of LAF does not conﬁrm grant of Loan by the Company;</p>
        <p class="p40">2.Company shall decide on grant of Loan based on merits of the application at its absolute discretion;</p>
        <p class="p40">3.information provided in LAF is complete and accurate and it/he/she shall promptly inform the Company in case of any change;</p>
        <p class="p40">4.He/she has understood each and every term of Loan and is ﬁnancially and legally eligible to avail Loan; Loan Amount shall be disbursed to the bank account, the details of which are furnished by the applicant;</p>
        <p class="p40">5.Company shall have the right to exchange and share all of the Applicant’s loan information including credit history and defaults to afﬁliate organizations of inter alia Company, Banks, Financial Institutions, Credit Bureaus, Statutory Bodies, Tax authorizes, and such other organizations as Company may deem ﬁt. Applicant shall not hold Company liable for use of this information;</p>
        <p class="p40">6.The applicant shall be solely, absolutely and unconditionally liable for the repayment of Payables, from time to time, which includes the extension request/provision for repayment, and shall make such payment notwithstanding any remainder, demand, notice sent or issued by Air Fish Lending Corporation or Service Provider in this regard;</p>
        <p class="p40">7.The applicant shall not, under any circumstances, withhold the payment of monies to the Company under these T& Cs; and have no objection in relation to any updates, messages or any other form of communication which the applicant may receive, from time to time, on the registered mobile number or email Id from the Company or the Service Provider.</p>
        <p class="p40">8.The applicant shall not have the right to assign, sell, transfer in any manner the rights and obligations under this T&Cs to any other person without the prior approval of the Company or Service Provider;</p>
        <p class="p40">9.Company reserves the right to reject the LAF and retain the form along with the photograph of Applicant;</p>
        <p class="p40">10.Applicant has not availed of a credit facility from any other other than the one expressly mentioned by it in the LAF;</p>
        <p class="p40">11.It/He/she will provide Company with any additional documents and do such deeds as is required by the Company pursuant to submitting this LAF.</p>
      </div>
      <p class="p40">I UNDERTAKE THAT I HAVE READ AND UNDERSTOOD PROVISIONS OF LAF INCLUDING DECLERATIONS, </p>
      <p class="p60">T&C AND UNDERTKAINGS MADE HEREIN AND I AGREE TO ABIDE BY THEM.</p>
      <p>Name of the Borrower: {{ info.name }}</p>
      <p>Date: {{ info.loan_time }}	</p>
      <!-- <p>Signature_______________ </p> -->
    </div>
    <loan></loan>
  </base-template>
</template>

<script>
import loan from '../index.vue'
import { getLoanAgreement, getStayPayList } from '@/api/axios/index'
export default {
  components: {
    loan
  },
  data() {
    return {
      isHideHeadBack:this.$route.query.ishideheadback || 0,
      info: {
        highest_term: '', // 期限
        useof_funds: '', // 贷款用途
        loan_cash: '', // 本金金额
        loan_time: '', // 贷款开始日期： 取当天日期
        interest: '', // 利率： 日利率：0.03%
        server_cash: '', // 手续费（包括消费税） ： 取服务费用
        overdue_penalty: '', // 延迟利息：  日利率：0.1%
        overdue_damages: '', // 逾期罚款：取逾期违约金
        idcard: '', // 身份证号
        name: '' // 姓名
      },
     
      // isExamine: this.$andirodData.phone_number + '' === '09123456015'
      isExamine:false
    }
  },
  created() {
    // this.getHomePage()
    console.log(this.$andirodData,'this.$andirodData');
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.changeStatusIsDarkFont(true)
    this.$H5Object.isShowBottomTabinter(false)
  },
  methods: {
    async getLoanAgreement(loan_cash, timers) {
      const {type} =this.$route.query
      let dto={}
      if(type=='my'){
        dto={ loan_cash: loan_cash, timers: timers,type:1 }
      }else{
        dto={ loan_cash: loan_cash, timers: timers }
      }
      
      const { data } = await getLoanAgreement(dto) || {}
      this.info = data || {}
      this.info={
        highest_term: data.LoanTerm, // 期限
        useof_funds:data.ukulemba_inkalata, // 贷款用途
        loan_cash: data.Palikuli, // 本金金额
        loan_time: data.Loan_term, // 贷款开始日期： 取当天日期
        interest: data.landapo, // 利率： 日利率：0.03%
        server_cash: data.rsrgeevi, // 手续费（包括消费税） ： 取服务费用
        overdue_penalty: data.UkufisaNokutaipa, // 延迟利息：  日利率：0.1%
        overdue_damages: data.UkufumyapoWei, // 逾期罚款：取逾期违约金
        idcard: data.card_Inyimbo, // 身份证号
        name: data.Full_name // 姓名

      }
    },

    async getHomePage() {
      try {
        const { data } = await getStayPayList()
        console.log(data,'datadatadatadatadatadata');
        const cash = (data?.Ukutantika_bwangu && data?.Ukutantika_bwangu[0]?.ukufumyaIfingi)||0
        const day = (data?.Ukutantika_bwangu && data?.Ukutantika_bwangu[0]?.Ukuftashi[0]?.dip_tgio) || 130
        const {type,cash_loan,day_loan} =this.$route.query
        if(type=='loan'){
          console.log(cash_loan ,day_loan );
          this.getLoanAgreement(cash_loan || 0,day_loan || 130)
        }else{
          console.log(cash,day);
          this.getLoanAgreement(cash , day )
        }
      } catch (err) {
        console.log(err)
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.content{
  background: #fff;
  padding: 32px 0;
  line-height: 34px;
  font-size: 28px;
}
.main-title{
  text-align: center;
}
.title-item{
  font-family: 'Calibri';
  font-weight: bold;
  margin-bottom: 15px;
}
.p10{
  padding-bottom: 10px;
}
.p20{
  padding-bottom: 20px;
}
.p40{
  padding-bottom: 40px;
}
.p60{
  padding-bottom: 60px;
}
.item-content{
  padding-left: 40px;
}
.bold{
  font-weight: bold;
}
.tab-cont{
  padding-left: 30px;
}
table {text-align: center;}
.log-img{
  width: 150px;
  height: 100px;
  margin: 0 auto;
  background: url('~@/assets/images/public/loan-title-icon.png') no-repeat center center;
  background-size: 100%;
}
.head-text{
  text-align: center;
}
.sig-img{
  width: 100%;
}
.hide-head-back /deep/ .headerpulic-left{
  display: none;
}
</style>
